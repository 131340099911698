@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: 'Roboto', Arial, sans-serif;
  overflow-y: auto;
}

/* Header */
.header {
  justify-content: space-between;
  align-items: center;
  padding: 1% 4%;
  min-height: 10%;
  max-height: 10%;
  flex-direction: row !important;
}

.headerIE {
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
  min-height: 12%;
  max-height: 12%;
  flex-direction: row !important;
}

.navWrapper {
  height: 10%;
}

/* Footer */
.footer {
  text-align: center;
  padding: 1%;
  top: auto !important;
  bottom: 0 !important;
  height: 10%;
  justify-content: center;
}

.footerLinksBar {
  display: flex;
  justify-content: center;
}

.footerLinks {
  margin: 0 1%;
  color: white;
}

.footerLinks>p {
  color: white;
}

.footerLinks>p:hover {
  cursor: pointer;
}

/* Nav */
.navMenuLogo {
  max-width: 150px;
  cursor: pointer;
}

.navHeader {
  font-family: 'Poppins';
  cursor: pointer;
  font-size: 2.125rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.00735em;
  margin-left: 4% !important;
  justify-content: center !important;
}

.navBar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.navBarLogo {
  max-height: 30px;
  cursor: pointer;
}

.navMenuLogoContainer {
  justify-content: center !important;
}

.logoutButton {
  justify-content: center !important;
}

/* Main Container */
.externalContainer {
  width: 100%;
  height: 97vh;
}

/* Page Container */
.pageContainer {
  text-align: center;
  width: 80%;
  height: 80%;
  margin-left: 10%;
  overflow: auto;
}

.ORIGINAL_internalContainer_ORIGINAL {
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: top;
  flex-direction: row;
  justify-content: space-evenly;
}

.internalContainer {
  width: 90%;
  margin: auto;
}

.internalContainerEdge {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.internalContainerIE {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.internalContainerCol {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 2% 0;
}

.pageContainerColumn {
  width: 80%;
  max-height: 80%;
  min-height: 80%;
  overflow: auto;
  padding: 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headerImage {
  max-width: 100%;
}

.headerImageAbout {
  max-width: 100%;
  max-height: 80%;
}

.headerImageFAQ {
  max-width: 100%;
  max-height: 80%;
}

.pageRow {
  display: flex;
  width: 100%;
}

.halfPageImage {
  max-width: 100%;
  padding: 10%;
}

.pageList {
  text-align: left;
}

.accordionSummary {
  text-align: left !important;
}

/* Translate Container */
.fullContainer {
  max-width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
  flex-grow: 1;
}

.fullSelectors {
  margin: 4% 0 4% 0 !important;
  width: 60%;
}

.fullTextField {
  width: 95%;
  margin: 0 1% 0 1% !important;
}

.fullPaperHeader {
  background-color: #ef6c00 !important;
  font-weight: bold;
  padding: 1% 0 1% 0 !important;
}

.authPaperHeader {
  background-color: #ef6c00 !important;
  font-weight: bold;
  padding: 1% 0 1% 0 !important;
  margin: 1% 0 0 0 !important;
  font-size: .85rem;
}

.fullPaperCriteria {
  background-color: #ef6c00 !important;
  font-weight: bold;
}

.fullPaperContent {
  background-color: #ef6c00 !important;
  margin: 0 0 1% 0 !important;
  font-weight: bold;
}

.fullTypogUpload {
  margin: 2% 0 2% 0 !important;
}

/* Step Container */
.halfContainer {
  max-width: 50%;
  text-align: center;
}

.stepContainer {
  margin-bottom: 5%;
  text-align: center;
}

.stepper {
  padding-top: 0 !important;
  background-color: transparent !important;
  justify-content: center !important;
}

.selectors {
  margin: 0 1% !important;
}

.textField {
  width: 50%;
}

.spinner {
  width: 25%;
  height: 25%;
}

.stepIEfix {
  visibility: hidden;
}

/* Options Container */
.optionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2%;
  width: 100%;
  margin: auto;
  overflow: auto;
  height: 100%;
}

.optionsCard {
  width: 100%;
  overflow: auto !important;
  padding: 5%;
}

.optionsItem {
  width: 30%;
  text-align: center;
}

.optionsButton {
  margin-bottom: 3%
}

.optionsHeader {
  margin-top: 0;
}

/* Logo Container */
.mainMedia {
  width: 80%;
  height: 10%;
  margin: auto;
  text-align: center;
  background-color: #ef6c00 !important;
}

.mainMediaIE {
  width: 80%;
  height: 100%;
  margin: auto;
  text-align: center;
  background-color: #ef6c00 !important;
}

.infoIcon {
  margin-bottom: 3% !important;
  text-align: center;
}

.mainImage {
  padding: 5%;
}

/* Profiles */
.profileContainer {
  margin: auto;
  width: 100%;
}

.details {
  display: flex;
  justify-content: space-evenly;
}

.loading {
  margin: 10%;
}

/* Profiles */
.profileContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.profileDetails {
  width: 40%;
  margin: 0 5%;
  min-height: 50%;
}

.profileDocuments {
  width: 40%;
  margin: 0 5%;
  min-height: 50%;
}

/* Utility Classes */
.displayNone {
  display: none;
}

/* Modal Styles */
.modalTitle {
  text-align: center;
}

.modalLink {
  cursor: pointer;
}

.modalContent {
  padding: 0 5%;
  text-align: center;
}

/* Headers */
.pageHeader {
  margin-bottom: 0;
}

/* Docs */
.fullDropdown {
  width: 100%;
}

.fullDetails {
  padding: 0 !important;
}

/* ------------------------------------------------ */
/* 3.0 styles */

/* Container for entire App */
.appContainer {
  width: 100%;
  height: 100%;
}

/* Container for pages */
.contentContainer {
  text-align: center;
  width: 90%;
  height: 90%;
  margin: auto;
}

/* AppBar for App */
.appBarMain {
  z-index: 5;
}

.appBarTitle {
  text-align: start;
  flex: 1;
  margin: 0 0 0 0.75% !important;
  font-family: 'Poppins';
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 1.5rem;
}

.appBarSideDrawer {
  text-align: start;
  flex: 1;
  margin: 0 0 0 2% !important;
  font-family: 'Poppins';
  font-weight: bold;
  letter-spacing: 0.02em;
  font-size: 1.5rem;
}

/* AppBar for Full Screen Edit */
.fullEditAppBar {
  position: 'relative';
}

.fullEditTitle {
  text-align: start;
  flex: 1;
}

.fullEditGrid {
  margin: 70px 0 0 0 !important;
}

/* Grid for Landing Page */
.landingGrid {
  margin: 50px 0 0 0 !important;
  padding-bottom: 50px;
}

.landingDescription {
  font-size: 1.2rem;
}

/* Speech and Text Analysis "Button" */
.link {
  text-decoration: none;
  color: #0078d4;
}

.link:hover {
  text-decoration: underline;
}

/* Grid for Translate Page */
.translateGrid {
  margin: 75px 0 35px 0 !important;
}

.translateCard {
  height: 100%;
}

.translateCardTitle {
  font-size: 1.2rem;
}

/* Grid for FAQ Page */
/*
.faqRoot {
  display: 'flex';
  z-index: 1;
}

.faqDrawer {
  width: 240;
  flex-shrink: 0;
  z-index: 1;
}

.faqDrawerPaper {
  width: 240;
  z-index: 1;
}

.faqDrawerSpacing {
  min-height: 75px;
}

.faqDrawerContent {
  flex-grow: 1;
  padding: theme.spacing(3);
  z-index: 1;
}

.faqGrid {
  margin: 75px 0 0 0 !important;
}

.faqList {
  width: 100%;
  background-color: #bdbdbd;
}

.faqListItem {
  margin: 0 0 0 5% !important;
}
*/

/* Footer */
.footerBarMain {
  text-align: center;
  top: auto !important;
  bottom: 0 !important;
  height: 5%;
  justify-content: center;
  z-index: 5;
}


/* Media Queries */
/* Mobile */
@media only screen and (min-device-width : 320px) and (max-device-width : 650px) {
  .externalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
  }

  .internalContainer {
    width: 100%;
    text-align: center;
    overflow: auto;
    padding-top: 2%;
    padding-bottom: 2%;
    flex-direction: column;
    justify-content: unset;
  }

  .halfContainer {
    max-width: 90%;
    width: 90%;
    margin: auto;
    margin: 5% auto 5% auto;
  }

  .navHeader {
    font-size: 1.3rem;
  }

  .navButton {
    font-size: .65rem !important;
  }

  .pageHeader {
    font-size: 1.75rem;
  }

  .pageRow {
    flex-direction: column;
  }

  .profileContainer {
    display: block;
    padding: 2%;
    margin: auto;
    overflow: auto;
  }

  .profileCard {
    width: 100%;
    margin: 5% 0;
  }

  .mainMedia {
    width: 100%;
  }
}

@media only screen and (min-device-height : 320px) and (max-device-height : 500px) and (min-device-width : 480px) {
  .header {
    min-height: 15%;
    max-height: 15%;
  }

  .footer {
    min-height: 15%;
    max-height: 15%;
  }

  .navWrapper {
    min-height: 15%;
    height: 15%;
  }

  .pageContainer {
    height: 70%;
  }

  .internalContainer {
    width: 90%;
    margin: auto;
    overflow: auto;
    display: block;
  }

  .halfContainer {
    padding: 2% 0;
    width: 100%;
    max-width: 100%;
  }

  .pageRow {
    flex-direction: column;
  }

  .profileContainer {
    display: block;
    padding: 2%;
    margin: auto;
    overflow: auto;
  }

  .profileCard {
    width: 100%;
    margin: 5% 0;
  }

  .mainMedia {
    width: 100%;
  }
}
